import React, { useState } from 'react';

const BoxComponent = ({ defaultText, hoverText, imageSrc, width, height, imgWidth, imgHeight }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const boxStyle = {
    width: width,
    height: height,
  };

  const imgStyle = {
    width: imgWidth,
    height: imgHeight,
  };

  return (
    <div
      className={`box ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={boxStyle}
    >
      <div className={`front-content ${isHovered ? 'hidden' : ''}`}>
        <img src={imageSrc} alt="" style={imgStyle} />
        <p className="default-text" style={{ marginTop: "10px" }}>{defaultText}</p>
      </div>
      <div className={`back-content ${isHovered ? '' : 'hidden'}`}>
        <p className="hover-text">{hoverText}</p>
      </div>
    </div>
  );
};

export default BoxComponent;
