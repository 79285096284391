import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

const LightAnimation = ({ customColors }) => {
  const [currentLight, setCurrentLight] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentLight((prevLight) => (prevLight + 1) % colors.length);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const defaultColors = ["#EDB936", "#E4C473", "#F8E4B2"];
  const colors = customColors || defaultColors;

  const getArrowStyles = (index) => {
    return {
      color: colors[index],
      fontSize: "50px", 
      margin: "-20px 0",
    };
  };


  return (
    <div  className="containerStyle">
      <div>
        <IoIosArrowDown style={getArrowStyles((currentLight + 2) % colors.length)} />
      </div>
      <div>
        <IoIosArrowDown style={getArrowStyles((currentLight + 1) % colors.length)} />
      </div>
      <div>
        <IoIosArrowDown style={getArrowStyles(currentLight)} />
      </div>
    </div>
  );
};

export default LightAnimation;
