import React from 'react'
import { useTranslation } from "react-i18next";
import Circle from "../../images/circle.png";

const Welcome = () => {
    const { t } = useTranslation();
    return (
      <div className="welcome">
        <div >
            <div className='welcome-text-area'>
                <h1 className="welcome-title1">
                {t("app.digitilazed")}
                </h1>
                <h1 className="welcome-title2">
                {t("app.now")}
                </h1>
                <p className="welcome-desc">
                {t("app.introtext")}                
                </p>
            </div>
            
            <div className='welcome-img-area'>
                <img src={Circle} className="welcome-img"  alt="" />
            </div>
            
        </div>
      </div>
    );
}

export default Welcome;