import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'animate.css';
import { useHistory } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs'; // React Icons'tan ilgili ikonu içe aktarıyoruz


const YellowCard = () => {
  const { t } = useTranslation(); // You forgot to initialize the useTranslation hook
  const loadMoreButtonRef = useRef(null); // Initialize useRef
  const history = useHistory();

  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    // Define your logic for animationTriggered
    // setAnimationTriggered(true);
  }, []);

  const handleLoadMoreClick = () => {
    history.push('/about');
  };

  const buttonStyle = {
    margin: '40px 3px',
    cursor: 'pointer',
    background:"black",
    color:"#FBEABE",
    padding:"10px 15px",
    borderRadius:"10px"
  };

  return (
    <div className="yellow-card">
    <div className="about-us-area">
      
      <p className="long-text">
        {t("app.homeabout")}
      </p>
   
      <a
 ref={loadMoreButtonRef}
 href="/about"
 style={buttonStyle}
 className={`slide-in ${animationTriggered ? 'animate__animated animate__fadeInLeft' : ''}`}
 onClick={handleLoadMoreClick}
>
   <span style={{ display: 'inline-block' }}>
    {t("app.more")}
    <BsArrowRightCircle
     color="#FBEABE"
     size={20}
     style={{ marginLeft: '10px' }}
    />
   </span>
  </a>
  

    </div>
    </div>
  );
};

export default YellowCard;
