import React from "react";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


const About = () => {
  const { t } = useTranslation();
  return (

    <div className="about" style={{ backgroundColor: "white" }}>
      <h1 className="about-title">
        <p style={{ color: "#0B528E" }}>{t("app.aboutus")}</p>
      </h1>

      <p className="aboutus-desc">
        <p>{t("app.aboutusDesc")}</p>
      </p>

      <p className="aboutus-desc" style={{ paddingBottom: "100px" }}>
        <p>{t("app.aboutusDesc2")}</p>
      </p>

      <VerticalTimeline>
        <VerticalTimelineElement
        className="vertical-timeline-element--work history-right"
        iconStyle={{ background: '#E4C473', color: '#fff' }}

        >
        <h3 className="vertical-timeline-element-title history-title-rigth">{t("app.historyTitle1")}</h3>

        <p className="history-desc-rigth">
        {t("app.historyDesc1")}
        </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work history-left"
        iconStyle={{ background: '#E4C473', color: '#fff' }}

        >
        <h3 className="vertical-timeline-element-title history-title-left"> {t("app.historyTitle2")}</h3>
        <p className="history-desc-left">
        {t("app.historyDesc2")}
        </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work history-right"
        iconStyle={{ background: '#E4C473', color: '#fff' }}

        >
        <h3 className="vertical-timeline-element-title history-title-rigth"> {t("app.historyTitle3")}</h3>
        <p className="history-desc-rigth">
        {t("app.historyDesc3")}
        </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work history-left"
        iconStyle={{ background: '#E4C473', color: '#fff' }}
        >
        <h3 className="vertical-timeline-element-title history-title-left">{t("app.historyTitle4")}</h3>
        <p className="history-desc-left">
        {t("app.historyDesc4")}
        </p>
        </VerticalTimelineElement>


        <VerticalTimelineElement
        className="vertical-timeline-element--work history-right"
        iconStyle={{ background: '#E4C473', color: '#fff' }}
        >
        <h3 className="vertical-timeline-element-title history-title-rigth">{t("app.historyTitle5")}</h3>
        <p className="history-desc-rigth">
        {t("app.historyDesc5")}
        </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work history-left"
        iconStyle={{ background: '#E4C473', color: '#fff' }}
        >
        <h3 className="vertical-timeline-element-title history-title-left">{t("app.historyTitle6")}</h3>
          <p className="history-desc-left">
            {t("app.historyDesc6")}
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work history-right"
        iconStyle={{ background: '#E4C473', color: '#fff' }}
        >
        <h3 className="vertical-timeline-element-title history-title-rigth">{t("app.historyTitle7")}</h3>
        <p className="history-desc-rigth">
        {t("app.historyDesc7")}
        </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work history-left"
        iconStyle={{ background: '#E4C473', color: '#fff' }}
        >
        <h3 className="vertical-timeline-element-title history-title-left"  >{t("app.historyTitle8")} </h3>
        <p className="history-desc-left" >
        {t("app.historyDesc8")}
        </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      <p className="aboutus-desc " style={{paddingTop:"25px", paddingBottom: "100px",textAlign:"center", paddingLeft:"15%",fontSize: "27px" }}>
        <p style={{ fontSize: "34px",fontWeight:"bold" }}>{t("app.historyend")}</p>
      </p>
    </div>
  );
};

export default About;
