import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from 'axios';

const contactUrl =
  "https://t3nfvkt6jf.execute-api.us-east-1.amazonaws.com/prod/contact";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false); 
  const [isError, setIsError] = useState(false); 
  const { t } = useTranslation();

  useEffect(() => {
    if (isSuccess || isError) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
        setIsError(false);
      }, 1000); 

      return () => clearTimeout(timer);
    }
  }, [isSuccess, isError]);

  const clearAlerts = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (email.trim() === "" || name.trim() === "" || surname.trim() === "" || message.trim() === "") {
      setAlert(t("app.fillAllFields"));
      return;
    }
    setAlert(null);
    const requestConfig = {
      headers: {
        "x-api-key": "PoTol33x6e5FHms60xwu7O7Mh0m3RlC80S610nrf",
      },
    };
    const requestBody = {
      name: name + "-" + surname,
      email: email,
      message: message,
    };
    axios.post(contactUrl, requestBody, requestConfig).then((response) => {
      console.log(response);
      setIsSuccess(true); // Set success state to true
      setTimeout(clearAlerts, 1000); // Clear alerts after 5 seconds
    }).catch((error) => {
      console.error(error);
      setIsError(true); // Set error state to true
      setTimeout(clearAlerts, 1000); // Clear alerts after 5 seconds
    });
  };

  return (
    <div className="contact-form-wrapper">
      <div className="contact-form-container">
      <div className="contact-us-container">
        <h2 className="contactushome-title">{t("app.contactUs")}</h2>
        <div className='contact-social-medias' >
        <a  href="https://www.instagram.com/appelier_tr/">
            <img
              className='contact-image'
              src='../images/instagram.png'
              alt='Instagram'
              width={100}
              height={100}
            />
          </a>
    
          <a  href="https://www.linkedin.com/company/appelier-yazılım-ve-danışmanlık-hizmetleri-ltd-şti/" >
            <img
              className='contact-image'
              src='../images/linkedin.png'
              alt='LinkedIn'
              width={100}
              height={100}
            />
          </a>

        </div>
      </div>       
       <form onSubmit={submitHandler}>
          <div className="name-inputs">
            <input type="text" value={name} onChange={(event) => setName(event.target.value)} placeholder={t("app.name")} className="home-contact-input-area" />
            <input type="text" value={surname} onChange={(event) => setSurname(event.target.value)} placeholder={t("app.surname")} className="home-contact-input-area" />
          </div>
          <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder={t("app.mail")} className="home-contact-input-area" />
          <textarea value={message} onChange={(event) => setMessage(event.target.value)} placeholder={t("app.note")} rows="4" className="home-contact-input-area"></textarea>
          <button type="submit" className="home-contact-button-area">{t("app.send")}</button>
        </form>
        {alert && <p style={{ color: "red" }}>{alert}</p>}
        {isSuccess && <p style={{ color: "green" }}>{t("app.sentSuccessfully")}</p>} 
        {isError && <p style={{ color: "red" }}>{t("app.sendingFailed")}</p>} 
      </div>
    </div>
  );
};

export default Contact;
