import React, { useState ,useEffect} from 'react';
import "../../App.css";
import { useTranslation } from 'react-i18next';
import OurServicesNonBusiness from './OurServicesNonBusiness';
import OurServicesBusiness from './OurServicesBusiness';
import OurServicesWebBased from './OurServicesWebBased';
import OurServicesBusinessMobil from './ourServicesMobil/OurServicesBusinessMobil';
import OurServicesNonBusinessMobil from './ourServicesMobil/OurServicesNonBusinessMobil';
import OurServicesWebBasedMobil from './ourServicesMobil/OurServicesWebBasedMobil';


export default function OurServices() {

  const { t, i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [business, setBusiness] = useState(true);
  const [nonBusiness, setNonBusiness] = useState(false);
  const [webBased, setWebBased] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBusiness = () => {
    setBusiness(true);
    setNonBusiness(false);
    setWebBased(false);
  };

  const handleNonBusiness = () => {
    setBusiness(false);
    setNonBusiness(true);
    setWebBased(false);
  };


  const handleWebBased = () => {
    setBusiness(false);
    setNonBusiness(false);
    setWebBased(true);
  };

  return (
    <div className="services">
     <h2 className="ourservices-title" style={{ color: "#0B528E" }}>
        {t('app.ourservices')}
      </h2>
      <div className="our-services-buttons">
      <button onClick={handleBusiness}     className={`${business ? 'our-services-button-clicked' : 'our-services-button'}`}><h3  className="our-services-button-text ">{t("app.business")}</h3></button>
      <button onClick={handleNonBusiness}  className={`${nonBusiness ? 'our-services-button-clicked' : 'our-services-button'}`}><h3  className="our-services-button-text ">{t("app.nonbusiness")}</h3></button>
      <button onClick={handleWebBased}    className={`${webBased ? 'our-services-button-clicked' : 'our-services-button'}`}><h3  className="our-services-button-text">{t("app.webbased")}</h3></button>

      </div>
     <div style={{borderBottom:"1px solid", borderColor:"#173752", marginTop:"20px"}} />
     
    
     {windowWidth > 768 ? 
      <>
        {business ? <OurServicesBusiness/> : <></>}
        {nonBusiness ? <OurServicesNonBusiness /> : <></>}
        {webBased ? <OurServicesWebBased /> : <></>}
      </>

       : 
    <>
            {business ? <OurServicesBusinessMobil/> : <></>}
            {nonBusiness ? <OurServicesNonBusinessMobil/> : <></>}
            {webBased ? <OurServicesWebBasedMobil /> : <></>}


    </>
  
    }     
    </div>
  );
}
