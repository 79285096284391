import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BoxComponent from "./BoxComponent";

const OurServicesHome = () => {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
  return (
    <div>
    <h2 className="ourserviceshome-title">
      {t("app.ourservices")}
    </h2>
    <div className="box-container our-services-area">
  <BoxComponent
    defaultText={t("app.mobile")}
    hoverText={t("app.mobileDesc")}
    imageSrc="/images/ourservicesmobile.png"
    imgWidth={50} 
    imgHeight={70} 
  />
  <BoxComponent
    defaultText={t("app.web")}
    hoverText={t("app.webDesc")}
    imageSrc="/images/pc.png"
    imgWidth={60} 
    imgHeight={60} 
  />
  <BoxComponent
    defaultText={t("app.digital")}
    hoverText={t("app.digitalDesc")}
    imageSrc="/images/digitalpc.png"
    imgWidth={50} 
    imgHeight={65} 
  />
   <BoxComponent
    defaultText={t("app.softwaredevelop")}
    hoverText={t("app.softwaredevelopDesc")}
    imageSrc="/images/pc2.png"
    imgWidth={50} 
    imgHeight={65} 
  />
  <BoxComponent
    defaultText={t("app.data")}
    hoverText={t("app.dataDesc")}
    imageSrc="/images/databasepc.png"
    imgWidth={50} 
    imgHeight={65} 
  />

   <BoxComponent
    defaultText={ t("app.socialmedia")} //değişcek
    hoverText={t("app.socialmediaDesc")} //değişcek 
   imageSrc=" /images/startup.png" //değişcek
    imgWidth={50} 
    imgHeight={65} 
  />
</div>


    </div>
  )
}

export default OurServicesHome;