import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import React, { useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";

const OurServicesNonBusinessMobil = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log("dil", currentLanguage) 
return (
    <>
     <div className= {currentLanguage==="tr" ? "tr-socialmedia our-services-images-mobil" :"en-socialmedia our-services-images-mobil"} />
     <div className= {currentLanguage==="tr" ? "tr-socialproject our-services-images-mobil" :"en-socialproject our-services-images-mobil"} /> 
    </>
);
};

export default OurServicesNonBusinessMobil;