import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';

const ContactUs = () => {
  const { t } = useTranslation();

  const AnyReactComponent = ({ text }) => (
    <div className="marker-container">
      <i className="fa fa-map-marker marker-style" />
      <div className="marker-text">{text}</div>
    </div>
  );

  
  const defaultProps = {
    center: {
      lat: 38.44593884296711,
      lng: 27.200220102369318
    },
    zoom: 17,
  };

  return (
    <div className="contact">
      <h2 className="contact-title">
        {t('app.contact')}
      </h2>
      <div className="container-fluid mt-5">
        <div className="card" style={{ background: '#F5E5BB', border: 'none', borderRadius: '0px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <div className="card-body">
            <div className="row">
              <div className="col-md-5 order-md-2">
                <div className="map-container" style={{ width: '100%', height: '300px' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBhjpHEwaGz30W8C5zVHSWJd2T79LOQKwg' }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                    <AnyReactComponent
                      lat={38.44593884296711}
                      lng={27.200220102369318}
                      text="APPELİER YAZILIM VE DANIŞMANLIK"
                    />
                  </GoogleMapReact>
                </div>
              </div>
              <div className="col-md-5 order-md-1">
                <div className="contact-info">
                  <div className="contactus-desc">
                    <p style={{fontWeight:"bold"}}> {t('app.ourContactPhone')}</p>
                    <p>{t('app.contactPhone')}  </p>
                  </div>

                  <div className="contactus-desc">
                  <p style={{fontWeight:"bold"}}> {t('app.ourContactMail')}</p>
                  <p> {t('app.contactMail')}</p>
                    </div>

                  <div className="contactus-desc">
                  <p style={{fontWeight:"bold"}}> {t('app.ourContactAddress')}</p>
                  <p> {t('app.contactAddress')}</p>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
