import React from "react";
import "../../App.css";
import Welcome from "../homeComponents/Welcome";
import Contact from "../homeComponents/Contact";
import References from "../homeComponents/References";
import OurServicesHome from "../homeComponents/OurServicesHome";
import LightAnimation from "../homeComponents/LightAnimation";
import GallerySlider from "../homeComponents/GallerySlider";

import Quotation from "../homeComponents/Quotation";

import PhotoGalleryTeam from "../homeComponents/PhotoGaleryTeam";
import YellowCard from "../homeComponents/YellowCard";




function Home() {
  return (
    <>
      <Welcome/>
      <LightAnimation /> 
      <GallerySlider /> 
      <Quotation/>
      <OurServicesHome/>
     
      <YellowCard/>
      <References/>
      <Contact/>
    
    </>
  );
}

export default Home;

/* <PhotoGalleryTeam/> */