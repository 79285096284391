import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/bundle";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-coverflow";
import { useTranslation } from "react-i18next";
import { EffectCoverflow, Pagination, Navigation } from 'swiper';

function GallerySlider() {
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [spaceBetween, setSpaceBetween] = useState(50);
  const [imageWidth, setImageWidth] = useState(300);
  const [imageHeight, setImageHeight] = useState(600);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    const autoplayDelay = 5000; // Delay between slides in milliseconds

    const autoplayInterval = setInterval(() => {
      if (swiperInstance && !swiperInstance.animating) {
        swiperInstance.slideNext(); // Go to the next slide if not animating
      }
    }, autoplayDelay);

    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) { // Telefon ekranı
        setSlidesPerView(4);
        setSpaceBetween(-60);
        setImageWidth(160);
        setImageHeight(300);
      }else if (windowWidth < 880) { // Tablet ekranı
        setSlidesPerView(4);
        setSpaceBetween(10);
        setImageWidth(160);
        setImageHeight(300);
      }
      else if (windowWidth < 1100) { // Tablet ekranı
        setSlidesPerView(4);
        setSpaceBetween(10);
        setImageWidth(200);
        setImageHeight(400);
      }
      else if (windowWidth < 1299) { // Tablet ekranı
        setSlidesPerView(4);
        setSpaceBetween(10);
        setImageWidth(250);
        setImageHeight(450);
      }
       else if (windowWidth < 1300) { // Tablet ekranı
        setSlidesPerView(3);
        setSpaceBetween(10);
        setImageWidth(160);
        setImageHeight(300);
      }
      else if (windowWidth < 1600) { // Tablet ekranı
        setSlidesPerView(4);
        setSpaceBetween(50);
        setImageWidth(260);
        setImageHeight(450);
      } else { // Bilgisayar ekranı
        setSlidesPerView(4);
        setSpaceBetween(0);
        setImageWidth(300);
        setImageHeight(520);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(autoplayInterval);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="galley-slider-area">
      <Swiper
        ref={swiperRef}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={slidesPerView}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 180,
          modifier: 1,
          slideShadows: false, 
        }}
        className="mySwiper"
        loop={true}
        loopedSlides={10} 
        spaceBetween={spaceBetween}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
      >
        {/* Your SwiperSlide components here */}
        <SwiperSlide>
          <img src="/images/gallery1.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery2.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery3.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery4.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery5.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery6.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>

        {/* Add duplicate slides for looping */}
        <SwiperSlide>
          <img src="/images/gallery7.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery8.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery9.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery10.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery11.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/images/gallery12.png" alt="" width={imageWidth} height={imageHeight} />
        </SwiperSlide>
        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow" >
        
             </div>
          <div className="swiper-button-next slider-arrow">
          </div>
        </div>
      </Swiper>
    </div>
  );
}

export default GallerySlider;
