
import React, { useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";

const OurServicesBusinessMobil= () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log("dil", currentLanguage)
 
  return (
    <div>
      <div className= {currentLanguage==="tr" ? "tr-erp our-services-images-mobil" :"en-erp our-services-images-mobil"} />
      <div className= {currentLanguage==="tr" ? "tr-b2b our-services-images-mobil" :"en-b2b our-services-images-mobil"} />
      <div className= {currentLanguage==="tr" ? "tr-ecommerce our-services-images-mobil" :"en-ecommerce our-services-images-mobil"} />
      <div className= {currentLanguage==="tr" ? "tr-portal our-services-images-mobil" :"en-portal our-services-images-mobil"} />
      <div className= {currentLanguage==="tr" ? "tr-stock our-services-images-mobil" :"en-stock our-services-images-mobil"} />
      <div className= {currentLanguage==="tr" ? "tr-more our-services-images-mobil" :"en-more our-services-images-mobil"} />
    </div>
  );
};

export default OurServicesBusinessMobil;