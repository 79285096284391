import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import React, { useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";
import OurServices from './OurServices';

const SectionWipesStyled = styled.div`
  overflow: hidden;
  .panel {
    height: 100vh;
    width: 100vw;
    text-align: center;
  }
  
  .panel span {
    position: relative;
    display: block;
    overflow: visible;
    top: 50%;
    font-size: 80px;
  }
  
  .panel.gray {
    background-color: #4B4F52;
  }
  
  .panel.turqoise {
    background-color: #38ced7;
  }
  
  .panel.green {
    background-color: #22d659;
    margin-bottom: 800px;
  }
  
  .panel.bordeaux {
    background-color: #953543;
  }
`;

const OurServicesNonBusiness = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log("dil", currentLanguage) 
return (
    <>
    <SectionWipesStyled>
        <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
        
        <Scene pin>
          <div className="panel">
          <div className= {currentLanguage==="tr" ? "tr-socialmedia our-services-images" :"en-socialmedia our-services-images"} />
          </div>
        </Scene>
        
        <Scene pin>
          <div className="panel">
          <div className= {currentLanguage==="tr" ? "tr-socialproject our-services-images" :"en-socialproject our-services-images"} />
          </div>
        </Scene>

        </Controller>
    </SectionWipesStyled>
     
    
    </>
);
};

export default OurServicesNonBusiness;