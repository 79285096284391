import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./Navbar.css";
import Logo from "../images/appelier-logo.png";
import { useTranslation } from "react-i18next";
import { HashLink } from 'react-router-hash-link';
import LogoVideo from "../videos/appelierlogo.mp4"; 

function Navbar() {
  const [click, setClick] = useState(false);
  const [active, setActive] = useState("1");
  const [videoWidth, setVideoWidth] = useState(window.innerWidth < 768 ? 200 : 350);
  const [videoHeight, setVideoHeight] = useState(window.innerWidth < 768 ? 60 : 80);

  const { t, i18n } = useTranslation();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleClickLang = (event) => {
    setActive(event.target.id);
  };

  function clickLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    const handleResize = () => {
      setVideoWidth(window.innerWidth < 960 ? 200 : 350);
      setVideoHeight(window.innerWidth < 960 ? 60 : 80);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
        <HashLink smooth to='/' className="navbar-logo"  onClick={closeMobileMenu}>
        <video width={videoWidth} height={videoHeight} src={LogoVideo} autoPlay playsInline muted loop />
</HashLink>

          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}> 
            <li >
            <HashLink smooth to='/about' className="navbar-link"  onClick={closeMobileMenu}>{t("app.about")}</HashLink>
            </li>
            <li>
            <HashLink smooth to='/ourservices' className="navbar-link"  onClick={closeMobileMenu}>{t("app.ourservices")}</HashLink>
            </li>
           
            <li >
            <HashLink smooth to='/contact' className="navbar-link"  onClick={closeMobileMenu}>{t("app.contact")}</HashLink>
            </li>
            <li >
              <nav className="nav-item-select">
                <button
                  key={1}
                  className={active === "1" ? "aactive" : ""}
                  id={"1"}
                  onClick={(e) => {
                    clickLanguage("en");
                    handleClickLang(e);
                  }}
                >
                  EN
                </button>
                <span className="divider"></span>

                <button
                  key={2}
                  className={active === "2" ? "aactive" : ""}
                  id={"2"}
                  onClick={(e) => {
                    clickLanguage("tr");
                    handleClickLang(e);
                  }}
                >
                  TR
                </button>
              </nav>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
/*
 <img src={Logo} width="150" height="50" alt="" />

  
      <video
         width={200}
         height={50}
          src={LogoVideo}
          autoPlay
          playsInline
          muted
          
        />

 */