import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import ContactUs from "./components/pages/ContactUs";

import OurServices from "./components/pages/OurServices";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} /> 
          <Route path='/about' component={About} />
          <Route path='/contact' component={ContactUs} />
          <Route path='/ourservices' component={OurServices} />

        </Switch>
      </Router>
    </>
  );
}

export default App;
