

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


const PhotoGalleryTeam = () => {
  const [images] = useState([

    { img: '../images/bilgecicek.png', name: 'Bilge', width: 187, height: 247 },
    { img: '../images/canerdikara3.jpg', name: 'Can', width: 187, height: 247 },
    { img: '../images/deniz2.jpeg', name: 'Deniz', width: 187, height: 247 },
    { img: '../images/ececam.png', name: 'Ece', width: 187, height: 247 },
    { img: '../images/egemensav.png', name: 'Egemen', width: 187, height: 247 },
    { img: '../images/hilalbastann.png', name: 'Hilal', width: 187, height: 247 },
    { img: '../images/sena2.jpeg', name: 'Sena', width: 187, height: 247 },

  ]);
  const { t } = useTranslation(); 

  const firstRowImages = images.slice(0, 4);
  const secondRowImages = images.slice(4, 7);

  return (
    <div className='galeryteamarea' style={{ textAlign: 'center'}}>
      <h1 className="about-card-title">{t('app.teams')}</h1>
      <div className="row">
        {firstRowImages.map((image, index) => (
          <div key={index} className="image-container">
             <div  className='team-image-border'>
            <img
             className='team-image'
               src={image.img}
              alt={`Image ${index}`}
              width={image.width}
              height={image.height}
            />
            </div>
            <p className='imagename'>{image.name}</p>
          </div>
        ))}
      </div>
      <div className="row">
        {secondRowImages.map((image, index) => (
          <div key={index} className="image-container">
            <div  className='team-image-border'>
            <img
              className='team-image'
              src={image.img}
              alt={`Image ${index}`}
              width={image.width}
              height={image.height}
            /></div>
            <p className='imagename'>{image.name}</p>
          </div>
        ))}
      </div>
      <p className='photo-gallery-team-text'>{t('app.photodescription')}</p>
    </div>
  );
};

export default PhotoGalleryTeam;
