import React from 'react';
import { useTranslation } from "react-i18next";

export default function Quotation() {
    const { t } = useTranslation();

  return (
    <div className="quotation-area">
      <span className="quote-left">&ldquo;</span>
      <div className="quote-text">      
        {t("app.quotation")}
       </div>
      <span className="quote-right">&rdquo;</span>
      <div className="quote-who">  {t("app.quotationWho")}</div>
    </div>
  );
}
