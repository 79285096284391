import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const References = () => {
  const { t } = useTranslation();
  const [videoWidth, setVideoWidth] = useState(window.innerWidth < 768 ? 60 : 180);
  const [videoHeight, setVideoHeight] = useState(window.innerWidth < 768 ? 40 : 80);
  const [videoWidthIs, setVideoWidthIs] = useState(window.innerWidth < 768 ? 85 : 240);
  const [videoWidthSmo, setVideoWidthSmo] = useState(window.innerWidth < 768 ? 50 : 150);


  useEffect(() => {
    const handleResize = () => {
      setVideoWidth(window.innerWidth < 768 ? 60 : 180);
      setVideoHeight(window.innerWidth < 768 ? 40 : 80);

      setVideoWidthIs(window.innerWidth < 768 ? 85 : 240);
      setVideoWidthSmo(window.innerWidth < 768 ? 50: 150);

    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logos = [
    "/images/references-monjardin.png",
    "/images/references-ektam.png",
    "/images/references-ods.png",
    "/images/references-tigaya.png",
    "/images/references-pars.png",
    "/images/references-is.png",
    "/images/references-smo.png",
    "/images/references-novatem.png",
    "/images/references-erma.png",
    "/images/references-mekta.png"
  ];

  return (
    <>
      <div className="ourteam-area">
        <h2 className="references-title">
          {t("app.references")}
        </h2>

        <div style={{display:"flex", textAlign:"center"}}>
          {logos.slice(0, 5).map((logo, index) => (
            <div key={index} style={{ flex: 1 }}>
              <img src={logo} alt={`Reference Logo ${index + 1}`} width={videoWidth} height={videoHeight}  />
            </div>
          ))}
        </div>

        <div style={{display:"flex", marginTop:"50px", textAlign:"center"}}>
          {logos.slice(5, 10).map((logo, index) => (
            <div key={index} style={{ flex: 1 }}>
              <img src={logo} alt={`Reference Logo ${index + 6}`} 
            width={
              index === 0 ? videoWidthIs : 
              index === 1 ? videoWidthSmo :
              videoWidth
            }
              height={videoHeight}
               />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default References;


/*
 <Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={false}  duration={1500} responsive={[{  
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }, {
        breakpoint: 100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }]}>
      <div> <img src="/images/references-monjardin.png" alt="" width={150} height={80}/></div>
      <div> <img src="/images/references-ektam.png" alt="" width={150} height={80}  /></div>
      <div> <img src="/images/references-ods.png" alt=""  width={140} height={70}/></div>
      <div> <img src="/images/references-is.png" alt=""  width={190}  height={50} style={{position:"relative",top:"20px", right:"30px"}}/></div> 
      <div> <img src="/images/references-tigaya.png" alt=""  width={200}  height={130} style={{position:"relative",bottom:"20px"}}/></div> 
      <div> <img src="/images/references-novatem.png" alt=""  width={120}  height={80} /></div> 
      <div> <img src="/images/references-smo.png" alt=""  width={150} height={90} /></div> 
      <div> <img src="/images/references-erma.png" alt=""  width={120}  height={70} /></div> 

    </Slide>
*/