
import React, { useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";

const OurServicesWebBasedMobil = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log("dil", currentLanguage)  
return (
    <>
    <div className= {currentLanguage==="tr" ? "tr-businesswebsite our-services-images-mobil" :"en-businesswebsite our-services-images-mobil"} />
    <div className= {currentLanguage==="tr" ? "tr-managerpanel our-services-images-mobil" :"en-managerpanel our-services-images-mobil"} />
  
    </>
);
};

export default OurServicesWebBasedMobil;